import './styles.css'
import App from './App'
import Intro from './Intro'
import { createRoot } from 'react-dom/client'

createRoot(document.getElementById('root')).render(
  <Intro>
    <App />
  </Intro>
)
