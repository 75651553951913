import { useRef, useState } from 'react'
import { Canvas, useFrame} from '@react-three/fiber'
import {
  useGLTF,
  useScroll,
  Scroll,
  OrbitControls,
  ScrollControls,
  Text,
  Text3D,
  RandomizedLight,
  MeshTransmissionMaterial,
} from '@react-three/drei'
import { Github, Mail, ScrollIcon, LinkedInIcon } from './icons.js'
export default function App({ ready }) {
  const [visible, setVisible] = useState(true)
  return (
    <>
      {visible && ready && <ScrollIcon />}
      <Canvas camera={{ position: [-2, -1, 20], fov: 40 }}>
        <fog attach="fog" args={['#FAEE84', 0, 500]} />
        <RandomizedLight />
        {/* <color attach="background" args={["#F5EA2F"]} /> */}
   
        <ScrollControls damping={1} pages={7.5} distance={1} horizontal>
          <Scroll html style={{ width: '100%' }} obscure>
            <div className="glass" style={{ position: 'absolute', top: `20vh`, left: '350vw' }}>
              <h1>Drive Music Player</h1>
    <p>An online music player application allowing users to upload and listen to songs from their Google Drive accounts.</p>
    <ul>
        <li>Users can upload and listen to songs from their Google Drive accounts.</li>
        <li>Seamless playlist sharing functionality for users.</li>
        <li>Integration of YouTube video streaming for songs, when available.</li>
    </ul>
    <p>GitHub Link: <a href="https://github.com/kavinask007/drive-music" target="_blank">github.com/kavinask007/drive-music</a></p>
    <p>Demo Link: <a href="https://drivify.kavinraj.online/demo/" target="_blank">drivify.kavinraj.online/demo/</a></p>
                </div>
            <div className="glass" style={{ position: 'absolute', top: `20vh`, left: '450vw' }}>
            <h1>Typing App</h1>
    <p>An online typing app developed by Kavin, available at <a href="https://ghost.kavinraj.online/">ghost.kavinraj.online</a>.</p>
    <ul>
        <li>Improves typing skills for users.</li>
        <li>Offers typing exercises and challenges.</li>
        <li>Tracks and displays typing speed and accuracy.</li>
        <li>User-friendly interface for an enhanced typing experience.</li>
    </ul>
    <p>Demo Link: <a href="https://ghost.kavinraj.online/">ghost.kavinraj.online</a></p>
            </div>
            <div className="glass" style={{ position: 'absolute', top: `20vh`, left: '550vw' }}></div>      
            <Contact />
          </Scroll>
         
          <Model ready={ready} setVisible={setVisible} />
        </ScrollControls>
        {/* <OrbitControls/> */}
      </Canvas>
    </>
  )
}

function Model({ ready, setVisible }) {
  const group = useRef()
  const text_group = useRef()
  const Iam = useRef()
  const kavin = useRef()
  const intro3 = useRef()
  const intro = useRef()
  const intro2 = useRef()
  const skills = useRef()
  const skillslist1 = useRef()
  const scroll = useScroll()
  const { nodes, materials } = useGLTF('/scene-draco.glb')
  useFrame(e => {
    if (scroll.offset < 0.0001) {
      setVisible(true)
    } else {
      setVisible(false)
    }
    group.current.rotation.y = scroll.offset * 30
    const r1 = scroll.range(0 / 4, 1 / 10)
    text_group.current.rotation.y = scroll.offset * 30
    Iam.current.rotation.y = scroll.offset * 20
    text_group.current.children[0].strokeOpacity = 1 - r1
    Iam.current.children[0].strokeOpacity = 1 - r1
    const r2 = scroll.range(0 / 10, 1 / 15)
    kavin.current.rotation.y = r1 * 2
    intro2.current.rotation.y = r1 * 1.9  
    const r3 = scroll.range(0.6 / 10, 1.2/ 10)
    intro.current.children[0].position.y = -25 + r3 * 50
     // console.log(r3);
    intro.current.rotation.y = r3 * 2
   
    const r_t = scroll.range(0.5 / 10, 2 / 10)
    const r4 = scroll.range(1 / 10, 2 / 10)
    intro3.current.children[0].position.x = 360 - r4 * 1200
    const r5 = scroll.range(1.0 / 10, 4.5 / 10)
    const r7 = scroll.range(1.0 / 10, 4.1 / 10)
    skills.current.children[0].opactity = r5 * 100
    skills.current.children[0].material.visible = false
    if (r5 > 0.22) {
      skills.current.children[0].material.visible = true
    }
    // console.log(r5)
    // console.log(skills.current.children[0].material)
    // console.log(getHeightValue(r5, -60, 40, 0.9));
     skills.current.children[0].position.y = getHeightValue(r5, -60, 40, 0.9)
    for (let i = 0; i < skillslist1.current.children.length; i++) {
      skillslist1.current.children[i].position.y = getHeightValue(r7, -50, 4, 0.9)
    }
 
  })
  return (
    <>
      <group ref={text_group}>
        <CustomText text="Hi" position={[3, 0, -25]} color="white" size={3} fill={0} />
      </group>
      <group ref={Iam}>
        <CustomText text="I'm" position={[14, 0, -50]} color="white" size={5} fill={0} />
      </group>
      <group ref={kavin}> 
        <Custom3DText size={20} position={[120, -5, -150]} text="Kavinraj" material= {<meshNormalMaterial />}/>
      </group>
      <group ref={intro}>
        <Custom3DText material={<meshNormalMaterial />} size={20} text="AI / ML" position={[80, -20, -150]} />
      </group>
      <group ref={intro2}>
        <Custom3DText size={9} position={[95, -1, -50]} material={<meshNormalMaterial />} text="I Do" />
      </group>
      <group ref={intro3}>
        <Custom3DText size={24} material={<meshNormalMaterial />} position={[310, 25, -200]} text="Web development" />
      </group>
      <group ref={skills}>
        <Custom3DText size={25} material={<meshNormalMaterial />} position={[40, -100, -550]} text="SKILLS" />
      </group>
      <group ref={skillslist1}>
        <Custom3DText size={8} position={[-100, -50, -250]} text="JS" material={<meshNormalMaterial />} />
        <Custom3DText size={3} position={[-20, -50, -60]} text="python" material={<meshToonMaterial color={"f4f4f4"} />} />
        <Custom3DText size={5} position={[40, -50, -220]} text="React" material={<meshNormalMaterial />} />
        <Custom3DText size={1.5} position={[10, -50, -20]} text="MLOPS" material={<meshNormalMaterial />} />
        <Custom3DText size={5} position={[10, -50, -120]} text="AWS" material={<meshNormalMaterial />} />
        <Custom3DText size={3} position={[50, -50, -60]} text="Docker" material={<meshNormalMaterial />} />
        <Custom3DText size={5} position={[30, -50, -100]} text="Linux" material={<meshNormalMaterial />} />
        {/* <Custom3DText size={18} position={[30, -50, -290]} text="Django" material={<meshNormalMaterial />} /> */}
        <Custom3DText size={7} position={[10, -50, -260]} text="Git" material={<meshNormalMaterial />} />
        {/* <Custom3DText size={18} position={[40, -50, -240]} text="Tenserflow" material={<meshNormalMaterial />} /> */}
      </group>
      
      <group ref={group} scale={0.001} position={[0, 0, -100]} dispose={null}>
        <group rotation={[-Math.PI / 2, 0, 0.03]}>
          <group position={[-102253.52, -210688.86, -17050.52]}>
            <mesh material={materials.Scene_Root} geometry={nodes.mesh_0.geometry} receiveShadow castShadow/>
            <mesh material={materials.Scene_Root} geometry={nodes.mesh_1.geometry}  receiveShadow castShadow />
            <mesh material={materials.Scene_Root} geometry={nodes.mesh_2.geometry} receiveShadow castShadow />
            <mesh material={materials.Scene_Root} geometry={nodes.mesh_3.geometry} receiveShadow castShadow />
          </group>

          <mesh position={[250000, -200000, 50000]}>
            <sphereGeometry args={[30000, 32, 32]} />
            <meshBasicMaterial color="#F5EA2F" />
          </mesh>
        </group>
      </group>
    </>
  )
}

function CustomText(props) {
  return (
    <Text
      color={props.color}
      fontSize={props.size}
      maxWidth={100}
      lineHeight={1}
      letterSpacing={0.02}
      anchorX="center"
      anchorY="middle"
      fillOpacity={props.fill}
      strokeWidth={'2%'}
      strokeColor={props.color}
      position={props.position}>
      {props.text}
    </Text>
  )
}
function Custom3DText(props) {
  return (
    <Text3D
      curveSegments={32}
      bevelEnabled
      bevelSize={0.04}  
      bevelThickness={0.1}
      height={0.5}
      lineHeight={0.5}
      letterSpacing={-0.06}
      size={props.size}
      position={props.position}
      font="/text3d_font.json">
      {props.text}
      {props.material}
    </Text3D>
  )
}

function Contact() {
  return (
    <div className="block flexcenter" style={{ position: 'absolute', top: `20vh`, left: '650vw' }}>
      <h1>Contact</h1>
      <div className="flexrow">
        <a target="_blank" href="https://github.com/kavinask007/">
          <Github />
        </a>
        <a href="mailto:kavinask007@gmail.com">
          <Mail />
        </a>

        <a target="_blank" href="https://www.linkedin.com/in/kavinrajas/">
          <LinkedInIcon />
        </a>
      </div>
    </div>
  )
}

function getHeightValue(scrollValue, minHeight, maxHeight, plateauDuration) {
  const normalizedValue = scrollValue > 0.5 ? 1 - scrollValue : scrollValue

  let easedValue
  if (normalizedValue <= 0.5) {
    easedValue = normalizedValue * 2
  } else if (normalizedValue <= 0.5 + plateauDuration) {
    easedValue = 1
  } else {
    const t = (normalizedValue - 0.5 - plateauDuration) / (1 - 0.5 - plateauDuration)
    easedValue = 1 - Math.pow(1 - t, 2)
  }

  const range = maxHeight - minHeight
  const height = minHeight + range * easedValue
  return height 
}
